<template>
    <div
        dir="ltr"
        class="ml-auto flex min-w-full items-center border-y border-neutral-200  bg-white px-6 sm:border-r sm:py-0"
    >
        <div class="-mx-6 flex shrink-0 items-end  p-6 sm:mx-0 sm:border-none sm:p-6 sm:px-0">
            <NuxtLinkLocale
                to="/"
                class="flex items-center gap-2"
            >
                <img
                    class="h-8 w-auto"
                    src="../assets/images/logo.png"
                    alt="FixSirat"
                ><span class="font-medium">FixSirat</span>
            </NuxtLinkLocale>
        </div>
        <div class="ml-auto flex items-center gap-6">
            <div class="flex h-12 items-center">
                <div
                    v-if="loading"
                    class="h-10 w-10"
                />
                <span v-else>
                    <NfsButton
                        v-if="!isAuthenticated && !loading"
                        class="uppercase"
                        @click="handleLogin"
                    >
                        {{ $t('BUTTON.ANY',{text: 'login'}) }}
                    </NfsButton>
                    <div
                        v-else
                        class="relative top-1"
                    >
                        <UserWidget
                            :name="user?.name"
                            :email="user?.email"
                            :picture="user?.avatar"
                        />
                    </div>
                </span>
            </div>
            <div :class="['border-l border-neutral-300 py-6 pl-6 transition-opacity sm:hidden', props.showIcon?'opacity-100' : 'opacity-0']">
                <NfsIcon
                    icon="hamberger-menu"
                    :cursor="true"
                    size="small"
                    @action="openSidebarMenu"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useAuth0 } from '@auth0/auth0-vue';
const props = defineProps({
    showIcon: {
        type: Boolean,
        default: true
    }
});
const emit = defineEmits<{
  (e: 'action'): void
}>();
const auth0 = process.client ? useAuth0() : undefined;
const user = useUser().value;

const handleLogin = () => {
    auth0?.loginWithRedirect({
        appState: {
            target: useRoute().path
        }
    });
};
const loading = auth0?.isLoading ?? true;
const isAuthenticated = computed(() => {
    return auth0?.isAuthenticated.value;
});
watchEffect(() => {
    if (isAuthenticated.value)
        setUser();
});

function setUser() {
    if (auth0?.user.value) {
        user.name = auth0!.user.value.name as string;
        user.email = auth0!.user.value.email as string;
        user.avatar = auth0!.user.value.picture as string;
    }
}
function openSidebarMenu() {
    emit('action');
}

</script>
