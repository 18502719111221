<template>
    <div
        dir="ltr"
        class="flex grow flex-col gap-y-5 overflow-y-auto border-x border-t border-neutral-200 bg-white px-6"
    >
        <div class="-mx-6 flex shrink-0 items-end border-b border-neutral-200 p-5 sm:mx-0 sm:border-none sm:p-6 sm:px-0">
            <NuxtLinkLocale
                to="/"
                class="flex items-center gap-2"
                @click="hideSidebarMenu"
            >
                <img
                    class="h-8 w-auto"
                    src="../assets/images/logo.png"
                    alt="FixSirat"
                ><span class="font-medium">FixSirat</span>
            </NuxtLinkLocale>
        </div>
        <nav class="flex flex-1 flex-col">
            <ul
                role="list"
                class="flex flex-1 flex-col gap-y-7"
            >
                <li>
                    <ul
                        role="list"
                        class="-mx-2 space-y-1"
                    >
                        <li
                            v-for="item in props?.navigations"
                            :key="item.name"
                        >
                            <NuxtLinkLocale
                                v-if="!item.children"
                                :to="item.href"
                                class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-neutral-200 hover:text-neutral-900"
                                :class="[item.current ? 'bg-neutral-200 text-neutral-900' : 'text-neutral-500']"
                                @click="hideSidebarMenu"
                            >
                                <div
                                    class="shrink-0 group-hover:text-neutral-900"
                                    :class="{ 'text-neutral-900': item.current }"
                                >
                                    <NfsIcon
                                        :icon="item.icon"
                                        size="small"
                                    />
                                </div>
                                {{ item.name }}
                            </NuxtLinkLocale>
                            <Disclosure
                                v-else-if="showSidebarMenuItem(item.name)"
                                v-slot="{ open }"
                                as="div"
                            >
                                <DisclosureButton :class="[item.current ? 'bg-gray-50' : 'hover:bg-gray-50', 'text-gray-700 flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6']">
                                    <div
                                        class="shrink-0 group-hover:text-neutral-900"
                                        :class="{ 'text-neutral-900': item.current }"
                                    >
                                        <NfsIcon
                                            :icon="item.icon"
                                            size="small"
                                        />
                                    </div>
                                    {{ item.name }}
                                    <ChevronRightIcon
                                        :class="[open ? 'text-gray-500 rotate-90' : 'text-gray-400', 'ml-auto h-5 w-5 shrink-0']"
                                        aria-hidden="true"
                                    />
                                </DisclosureButton>
                                <DisclosurePanel
                                    as="ul"
                                    class="mt-1 px-2"
                                >
                                    <li
                                        v-for="subItem in item.children"
                                        :key="subItem.name"
                                    >
                                        <NuxtLinkLocale
                                            :to="subItem.href"
                                            class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-neutral-200 hover:text-neutral-900"
                                            :class="[subItem.current ? 'bg-neutral-200 text-neutral-900' : 'text-neutral-500']"
                                            @click="hideSidebarMenu"
                                        >
                                            <div
                                                class="shrink-0 group-hover:text-neutral-900"
                                                :class="{ 'text-neutral-900': item.current }"
                                            >
                                                <NfsIcon
                                                    :icon="subItem.icon"
                                                    size="small"
                                                />
                                            </div>
                                            {{ subItem.name }}
                                        </NuxtLinkLocale>
                                    </li>
                                </DisclosurePanel>
                            </Disclosure>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup lang="ts">
import {
    Disclosure, DisclosureButton, DisclosurePanel
} from '@headlessui/vue';
import { ChevronRightIcon } from '@heroicons/vue/20/solid';
import { useAuth0 } from '@auth0/auth0-vue';
import { type navItemObject } from '../types/navItemObject';
import { UserPermissionType } from '../../shared/shared.interface';
import { useUserHasPermissions } from '../composables/userPermissions';
import { adminUsersManagement } from '../types/constants';
const auth0 = process.client ? useAuth0() : undefined;

const showUsersManagement = ref(false);
const props = defineProps({
    navigations: {
        type: Array as PropType<navItemObject[]>,
        default: () => ([])
    }
});
const emit = defineEmits<{
    (e: 'action'): void
}>();

showUsersManagement.value = await isUsersManager();

function hideSidebarMenu() {
    emit('action');
}
function showSidebarMenuItem(itemName:string) {
    let showMenuItem = true;
    if ((!showUsersManagement.value && itemName === adminUsersManagement.name))
        showMenuItem = false;

    return showMenuItem;
}

watchEffect(async() => {
    if (auth0?.isAuthenticated.value)
        showUsersManagement.value = await isUsersManager();
});

async function getAuthToken() {
    if (!auth0 || !auth0?.isAuthenticated || !auth0?.isAuthenticated.value)
        return null;

    return await auth0!.getAccessTokenSilently();
}

async function isUsersManager() {
    const jwtToken = await getAuthToken();
    if (jwtToken)
        return await useUserHasPermissions(jwtToken, [ UserPermissionType.MANAGE_USERS ]);
    else
        return false;
}

</script>
