<template>
    <nav
        class=" border-neutral-200 bg-white"
        aria-label="Breadcrumb"
    >
        <ol
            role="list"
            class="mx-auto mt-0 flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8"
        >
            <li class="flex">
                <div class=" m-3 flex items-center">
                    <NuxtLink
                        href="/"
                        class="text-gray-400 hover:text-gray-500"
                    >
                        <NfsIcon
                            icon="home-icon"
                            size="xSmall"
                        />
                        <span class="sr-only">Home</span>
                    </NuxtLink>
                </div>
            </li>
            <li
                v-for="item in breadcrumbs"
                :key="item.name"
                class="flex"
            >
                <div
                    v-if="item.name != 'Dashboard' "
                    class="flex items-center"
                >
                    <svg
                        class="h-full w-6 shrink-0 text-neutral-200"
                        viewBox="0 0 24 44"
                        preserveAspectRatio="none"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <NuxtLink
                        :to="item.href"
                        class="hover:text-gray-700 ml-4 text-sm font-medium text-neutral-500"
                        :aria-current="item.current ? 'page' : undefined"
                    >
                        {{ item.name }}
                    </NuxtLink>
                </div>
            </li>
        </ol>
    </nav>
</template>

<script setup  lang="ts">

const { breadcrumbs } = useBreadcrumbs();

</script>
