<template>
    <Html
        :lang="htmlAttrs.lang"
        :dir="htmlAttrs.dir"
    >
        <NfsSelect
            id="languageSwitcher"
            :bordered="false"
            :options="localesOptions"
            @update="updateLocale"
        />
    </Html>
</template>

<script setup lang="ts">
import { type LocaleObject } from 'vue-i18n-routing';
import { type selectInputOption } from '../types/selectInputOption';

const { locales, setLocale, defaultLocale } = useI18n();
const availableLocales = locales.value as Array<LocaleObject>;
const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true
});
const htmlAttrs = computed(() => head.value.htmlAttrs!);
const activeLocale = ref();

function getLocaleFromCode(localeCode: string) {
    return availableLocales.find(locale => locale.code === localeCode);
}
activeLocale.value = getLocaleFromCode(useCookie('i18n_cookie_locale').value || defaultLocale);

function updateLocale(selectedValue:any) {
    setLocale(selectedValue);
    activeLocale.value = getLocaleFromCode(selectedValue);
}

const localesOptions = computed(() => availableLocales.map((a) => {
    return {
        text: a.name,
        value: a.code,
        selected: a.code === activeLocale.value.code
    } as selectInputOption;
}));

</script>
