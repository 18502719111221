import { navItemObject } from 'types/navItemObject';

export default function(navigations: navItemObject[]): navItemObject[] {
    const UpdatedNavigation = [];
    for (const item of navigations) {
        if (!item.children)
            if (useRoute().path === item.href)
                item.current = true;
            else
                item.current = false;
        else if (item.children)
            for (const subItem of item.children)
                if (useRoute().path === subItem.href)
                    subItem.current = true;
                else
                    subItem.current = false;

        UpdatedNavigation.push(item);
    }
    return UpdatedNavigation;
}
