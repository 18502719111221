<template>
    <div>
        <MenuDropDown
            :options="menuOptions"
            :show-default-button="false"
            menu-btn-type="transparent"
            @menu-option-clicked="handleLogout"
        >
            <img
                class="h-10 w-10 rounded-full"
                :src="props?.picture"
                :alt="props?.name"
            >
        </MenuDropDown>
    </div>
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
const auth0 = process.client ? useAuth0() : undefined;
const props = defineProps({
    name: {
        type: String,
        default: ''
    },
    email: {
        type: String,
        default: ''
    },
    picture: {
        type: String,
        default: ''
    }
});

const menuOptions = [
    {
        title: props?.name,
        link: ''
    },
    {
        title: props?.email,
        link: ''
    },
    {
        title: 'Logout',
        link: '',
        callback: true
    }
];

const handleLogout = (option) => {
    if (option.title === 'Logout')
        auth0?.logout({
            logoutParams: {
                returnTo: window.location.origin
            }
        });
};
</script>

<style scoped>
.user-details {
  @apply z-[1] p-4 shadow bg-neutral-100 w-64
}
</style>
